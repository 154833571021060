import React from "react";
import Container from "@mui/material/Container";
import Box from "@mui/material/Box";
import Grid from "@mui/material/Grid";
import MoreVertIcon from "@mui/icons-material/MoreVert";
import Typography from "@mui/material/Typography";
import IconButton from "@mui/material/IconButton";
import OpenConsultations from "./OpenConsultations";
export default function MobileDashboardLayout({ children }) {
  const onclickHandler = (item) => {};
  return (
    <Container maxWidth="false" sx={{ height: "100%" }} disableGutters={true}>
      <Grid
        sx={{ height: "100%" }}
        container
        direction="column"
        justifyContent="stretch"
        alignItems="stretch"
        flexWrap="nowrap"
        overflow={"hidden"}
      >
        <Grid item sx={{ zIndex: 1 }}>
          <Box
            display={"flex"}
            justifyContent={"space-between"}
            alignItems={"center"}
            pl={2}
            sx={{ borderBottom: "solid grey 1px" }}
          >
            <Typography
              variant="tittle"
              noWrap
              component="div"
              sx={{ flexGrow: 1 }}
            >
              Consultations
            </Typography>
            <IconButton
              size="large"
              color="lightgrey"
              aria-label="open drawer"
              onClick={onclickHandler}
            >
              <MoreVertIcon />
            </IconButton>
          </Box>
        </Grid>
        <Grid item sx={{ flexGrow: 1, overflow: "hidden" }}>
          <OpenConsultations />
        </Grid>
      </Grid>
    </Container>
  );
}

export const getLocalAuth = () => {
  const accessToken = localStorage.getItem("accessToken");
  const refreshToken = localStorage.getItem("refreshToken");
  const user = JSON.parse(localStorage.getItem("user"));
  if (!user || !accessToken || !refreshToken) return null;
  return { accessToken, refreshToken, user };
};
export const setLocalAuth = (data) => {
  if (!data) return null;
  const user = JSON.parse(atob(data.accessToken.split(".")[1]));
  localStorage.setItem("user", JSON.stringify(user));
  localStorage.setItem("accessToken", data.accessToken);
  localStorage.setItem("refreshToken", data.refreshToken);
};

export const deletLocalAuth = () => {
  localStorage.removeItem("user");
  localStorage.removeItem("accessToken");
  localStorage.removeItem("refreshToken");
};

import * as React from "react";
import Box from "@mui/material/Box";
import Container from "@mui/material/Container";
import Typography from "@mui/material/Typography";
import { Paper } from "@mui/material";
import { useGetConsultation } from "../../hooks/useConsultation";
import ChatView from "./ChatView";
import NavBar from "../NavBar";
import { useParams } from "react-router-dom";
import Grid from "@mui/material/Grid";
import Loader from "../Utility/Loader";
import { useScreenDetector } from "../../hooks/useScreenDetector";
import AssignConsultation from '../AssignConsutation'
import { useNavigate } from "react-router-dom";
import Button from "@mui/material/Button";
const ChatHeader = ({ title, consultationId, consultationType }) => {
  const navigate = useNavigate();

  const openVideoScreen = () => {
    navigate(`/consultation/${consultationId}/video`);
  };
  return (
    <Box
      sx={{ borderBottom: 1, borderColor: "divider", px: 2, py: 1 }}
      display="flex"
      justifyContent="space-between"
    >
      <Typography variant="h6" color="text.primary">
        {title}
      </Typography>
      {consultationType===2 && <Button type="submit" variant="outlined" onClick={openVideoScreen}>
        Join Video
      </Button>}
    </Box>
  );
};
export default function Chat() {
  let { consultationId } = useParams();
  const { isDesktop } = useScreenDetector();

  const { getConsultation, data, loading } = useGetConsultation();

  React.useEffect(() => {
    getConsultation(consultationId);
  }, [consultationId]);

  const isAssigned = ()=> data.status === 2

  return (
    <Container maxWidth="false" sx={{ height: "100%" }} disableGutters={true}>
      {loading && <Loader />}
      {data && (
        <Grid
          sx={{ height: "100%" }}
          container
          direction="column"
          flexWrap="nowrap"
        >
          <Grid item sx={{ zIndex: 1 }}>
            {!isDesktop && <NavBar title={data.name} />}
            {isDesktop  && (
              <ChatHeader title={data.name} consultationId={consultationId} consultationType={data.type}/>
            )}
          </Grid>
          <Grid item sx={{ flexGrow: 1, overflow: "hidden" }}>
            {isAssigned(data) && <ChatView consultation={data} />}
            {!isAssigned(data) && <AssignConsultation consultation={data} />}
          </Grid>
        </Grid>
      )}
    </Container>
  );
}

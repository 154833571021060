import React, { useEffect } from "react";
import Button from "@mui/material/Button";
import Box from "@mui/material/Box";
import { useAssignConsultation } from "../../hooks/useConsultation";
import Loader from "../Utility/Loader";
export default function AssignConsultation({ consultation }) {
  const { data, loading, assignConsultation } = useAssignConsultation();

  useEffect(() => {
    if (data && data.id) {
      window.location.reload();
    }
  }, [data]);

  const handleClickAssign = () => {
    assignConsultation(consultation.id);
  };

  return (
    <Box
      sx={{
        margin: 2,
        height: "200px",
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
      }}
    >
      <Button variant="outlined" onClick={handleClickAssign} disabled={loading}>
        Assign
      </Button>
    </Box>
  );
}

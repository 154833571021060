import * as React from "react";
import Box from "@mui/material/Box";
import Container from "@mui/material/Container";
import Typography from "@mui/material/Typography";
import { Paper } from "@mui/material";
import { useGetConsultation } from "../../hooks/useConsultation";
import { useParams } from "react-router-dom";
import Grid from "@mui/material/Grid";
import Loader from "../Utility/Loader";
import ChatView from "../Chat/ChatView";
import Video from "../Video";
const ChatHeader = ({ title }) => {
  return (
    <Box sx={{ borderBottom: 1, borderColor: "divider", px: 2, py: 1 }}>
      <Typography variant="h6" color="text.primary">
        {title}
      </Typography>
    </Box>
  );
};
export default function Call() {
  let { consultationId } = useParams();
  const { getConsultation, data, loading } = useGetConsultation();

  React.useEffect(() => {
    getConsultation(consultationId);
  }, [consultationId]);

  return (
    <Container maxWidth="false" sx={{ height: "100%" }} disableGutters={true}>
      {loading && <Loader />}
      {data && (
        <Grid
          sx={{ height: "100%", padding: 2 }}
          container
          columnSpacing={2}
          flexWrap="nowrap"
        >
          <Grid id item xs={3}>
            <Paper variant="outlined" sx={{ height: "100%" }}>
              <Container
                maxWidth="false"
                sx={{ height: "100%" }}
                disableGutters={true}
              >
                <Grid
                  sx={{ height: "100%" }}
                  container
                  direction="column"
                  flexWrap="nowrap"
                >
                  <Grid item sx={{ zIndex: 1 }}>
                    <ChatHeader
                      title={data.name}
                      consultationId={consultationId}
                    />
                  </Grid>
                  <Grid item sx={{ flexGrow: 1, overflow: "hidden" }}>
                    <ChatView consultation={data} />
                  </Grid>
                </Grid>
              </Container>
            </Paper>
          </Grid>
          <Grid item sx={{ flexGrow: 1 }}>
            <Paper variant="outlined" sx={{ height: "100%" }}>
              <Video consultation={data} />
            </Paper>
          </Grid>
        </Grid>
      )}
    </Container>
  );
}

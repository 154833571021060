import React from "react";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import Container from "@mui/material/Container";
import Grid from "@mui/material/Grid";
function Copyright(props) {
  return (
    <Typography
      variant="body2"
      color="text.secondary"
      align="center"
      {...props}
    >
      {"Copyright © Unify Health "}
      {new Date().getFullYear()}
    </Typography>
  );
}

// TODO remove, this demo shouldn't need to reset the theme.

export default function SignUp({ children }) {
  return (
    <Container maxWidth="false" height="100%" disableGutters={true}>
      <Grid
        container
        direction="column"
        justifyContent="flex-start"
        alignItems="stretch"
      >
        <Grid item>
          <Box> {children}</Box>
        </Grid>
        <Grid item>
          <Copyright sx={{ mt: 5 }} />
        </Grid>
      </Grid>
    </Container>
  );
}

import React from "react";
import { createTheme, ThemeProvider } from "@mui/material/styles";

const defaultTheme = createTheme();

const AppThemeProvider = ({children})=>{
  return (
    <ThemeProvider theme={defaultTheme}>
        {children}
    </ThemeProvider>
  );
}

export default AppThemeProvider;

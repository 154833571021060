import * as React from "react";
import Backdrop from "@mui/material/Backdrop";
import CircularProgress from "@mui/material/CircularProgress";
export default function Loader() {
  return (
    <div>
      <Backdrop sx={{ color: "#fff", zIndex:1000 }} open={true}>
        <CircularProgress color="inherit" />
      </Backdrop>
    </div>
  );
}

import * as React from "react";
import BottomNavigation from "@mui/material/BottomNavigation";
import BottomNavigationAction from "@mui/material/BottomNavigationAction";
import CalendarMonth from "@mui/icons-material/CalendarMonth";
import Person from "@mui/icons-material/Person";
import { useNavigate } from "react-router-dom";
import Paper from "@mui/material/Paper";
export default function MobileBottomNav() {
  const [value, setValue] = React.useState(0);
  const navigate = useNavigate();
  const onNavigate = (path) => {
    navigate(`/${path}`);
  };
  return (
    <Paper elevation={3}>
      <BottomNavigation
        showLabels
        value={value}
        onChange={(event, newValue) => {
          setValue(newValue);
          onNavigate(newValue);
        }}
      >
        <BottomNavigationAction
          label="Consultation"
          value={"care"}
          icon={<CalendarMonth />}
        />
        <BottomNavigationAction
          label="Account"
          value={"account"}
          icon={<Person />}
        />
      </BottomNavigation>
    </Paper>
  );
}

import React from "react";
import Grid from "@mui/material/Grid";
import Box from "@mui/material/Box";
import Consultations from "../Consultations";
import { Paper } from "@mui/material";

export default function SignUp({ children }) {
  return (
    <Grid
      sx={{ height: "100%", padding: 2 }}
      container
      columnSpacing={2}
      flexWrap="nowrap"
    >
      <Grid id item xs={3} >
        <Paper variant="outlined" sx={{ height: "100%" }}>
          <Consultations />
        </Paper>
      </Grid>
      <Grid item sx={{ flexGrow: 1 }}>
        <Paper variant="outlined" sx={{ height: "100%" }}>
          {children ? (
            children
          ) : (
            <Box
              display="flex"
              height={"100%"}
              flexGrow={1}
              justifyContent={"center"}
              alignItems={"center"}
            >
              Select Case
            </Box>
          )}
        </Paper>
      </Grid>
    </Grid>
  );
}

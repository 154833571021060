import React from "react";
import Container from "@mui/material/Container";
import Box from "@mui/material/Box";
import Grid from "@mui/material/Grid";
import MobileBottomNav from "../../../MobileBottomNav";

export default function MobileDashboardLayout({ children }) {
  return (
    <Container maxWidth="false" sx={{ height: "100%" }} disableGutters={true}>
      <Grid
        sx={{ height: "100%" }}
        container
        direction="column"
        justifyContent="stretch"
        alignItems="stretch"
        flexWrap="nowrap"
        overflow={"hidden"}
      >
        <Grid item sx={{ flexGrow: 1, overflow: "hidden" }}>
          {children}
        </Grid>
        <Grid item sx={{ zIndex: 1 }}>
          <MobileBottomNav />
        </Grid>
      </Grid>
    </Container>
  );
}

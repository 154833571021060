import { useContext } from "react";
import useFetch from "./useFetch";
import { loginApi, refreshApi, registerApi } from "../api/auth";
import { AuthContext } from "../context/AuthContext";
import { deletLocalAuth } from "../utils/localStorage";
export const useLoginAuth = () => {
  const { data, error, loading, request } = useFetch(loginApi);
  const { dispatch } = useContext(AuthContext);

  if (data) {
    dispatch({
      type: "login",
      data: data.data,
    });
  }
  return { login: request, data, error, loading };
};

export const useRegisterAuth = () => {
  const { data, error, loading, request } = useFetch(registerApi);
  return { register: request, data, error, loading };
};

export const useRefreshAuth = () => {
  const { data, error, loading, request } = useFetch(refreshApi);
  const { dispatch } = useContext(AuthContext);
  if (data) {
    dispatch({
      type: "refresh",
      data: data.data,
    });
  }
  return { refresh: request, data, error, loading };
};

export const useLogoutAuth = () => {
  const { dispatch } = useContext(AuthContext);
  const logout = () => {
    deletLocalAuth();
    dispatch({ type: "logout" });
  };
  return { logout };
};

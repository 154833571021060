import useFetch from "./useFetch";
import {
  getConsultationApi,
  getOpenConsultationsApi,
  assignConsultationApi,
} from "../api/consultation";
import { getChatAuthApi } from "../api/chat";

export const useGetOpenConsultations = () => {
  const { data, error, loading, request } = useFetch(getOpenConsultationsApi);
  return { getOpenConsultations: request, data, error, loading };
};

export const useGetConsultation = () => {
  const { data, error, loading, request } = useFetch(getConsultationApi);
  return { getConsultation: request, data, error, loading };
};
export const useAssignConsultation = () => {
  const { data, error, loading, request } = useFetch(assignConsultationApi);
  return { assignConsultation: request, data, error, loading };
};

export const useGetChatAuth = () => {
  const { data, error, loading, request } = useFetch(getChatAuthApi);
  return { getChatAuth: request, data, error, loading };
};

import React from "react";
import Container from "@mui/material/Container";
import Box from "@mui/material/Box";
import Grid from "@mui/material/Grid";
import AppBar from "../../../AppBar";

export default function SignUp({ children }) {
  return (
    <Container maxWidth="false" height="100%" disableGutters={true}>
      <Grid
        container
        direction="column"
        justifyContent="flex-start"
        alignItems="stretch"
      >
        <Grid item >
          <AppBar />
        </Grid>
        <Grid item >
          <Box> {children}</Box>
        </Grid>
      </Grid>
    </Container>
  );
}

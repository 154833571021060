import { createContext, useReducer, useEffect } from "react";
import { getLocalAuth, setLocalAuth } from "../utils/localStorage";

export const AuthContext = createContext(null);

export function AuthProvider({ children }) {
  const [auth, dispatch] = useReducer(authReducer, initialAuth);
  useEffect(() => {
    setLocalAuth(auth);
  }, [auth]);

  return (
    <AuthContext.Provider value={{ auth, dispatch }}>
      {children}
    </AuthContext.Provider>
  );
}
const initialAuth = getLocalAuth();

const authReducer = (auth, action) => {
  switch (action.type) {
    case "login": {
      return action.data;
    }
    case "refresh": {
      return action.data;
    }
    case "logout": {
      return null;
    }
    case "change": {
      return auth.map((t) => {
        if (t.id === action.task.id) {
          return action.task;
        } else {
          return t;
        }
      });
    }
    case "remove": {
      return auth.filter((t) => t.token !== action.token);
    }
    default: {
      throw Error("Unknown action: " + action.type);
    }
  }
};

import * as React from "react";
import Box from "@mui/material/Box";
import { useGetOpenConsultations } from "../../hooks/useConsultation";
import List from "@mui/material/List";
import Moment from "moment";
import Typography from "@mui/material/Typography";
import { useNavigate } from "react-router-dom";
import Loader from "../Utility/Loader";
import { Paper } from "@mui/material";
import { useParams } from "react-router-dom";
import Stack from "@mui/material/Stack";
const CardItem = ({ item, currentId, onclickHandler }) => {
  let { consultationId = 0 } = useParams();

  const { status, id, name, createdAt } = item;
  const isActive = status === 2;
  const isSelected = consultationId == id;
  const minDifference = Moment(createdAt).fromNow();

  return (
    <Box sx={{ my: 2, mx: isSelected ? 1 : 2, cursor: "pointer" }}>
      <Paper
        elevation={isSelected ? 4 : 1}
        sx={{
          padding: 2,
          display: "flex",
          borderLeft: `solid 4px ${isActive ? "green" : "red"}`,
          justifyContent: "space-between",
          alignItems: "center",
        }}
        onClick={() => onclickHandler(item)}
      >
        <Stack
          direction="row"
          justifyContent="space-between"
          alignItems="center"
          flexGrow={1}
        >
          <Stack>
            <Typography variant="title" color="text.primary" fontWeight={400}>
              {name}
            </Typography>
          </Stack>
          <Typography variant="caption" color="text.secondary" fontWeight={200}>
            {minDifference}
          </Typography>
        </Stack>
      </Paper>
    </Box>
  );
};
export default function OpenConsultations() {
  const { data, loading, getOpenConsultations } = useGetOpenConsultations();
  const navigate = useNavigate();
  React.useEffect(() => {
    getOpenConsultations();
  }, []);
  const onclickHandler = (item) => {
    navigate(`/consultation/${item.id}`);
  };
  if (loading) {
    return <Loader />;
  }
  if (data) {
    return (
      <Box sx={{ height: "100%", overflow: "scroll" }}>
        {!data && <Loader />}
        <List>
          {data.map((item) => (
            <CardItem
              key={item.id}
              item={item}
              onclickHandler={onclickHandler}
            />
          ))}
        </List>
      </Box>
    );
  }
}

import React from "react";
import {AuthProvider} from "../../context/AuthContext";
import AppThemeProvider from "../../context/ThemeContext";
import RouteApp from "../../routes";

function App() {
  return (
    <AppThemeProvider>
      <AuthProvider>
        <RouteApp />
      </AuthProvider>
    </AppThemeProvider>
  );
}

export default App;

// axiosConfig.js
import axios from 'axios';

const apiClient = axios.create({
  baseURL: 'https://api.unifi.dev',
  // baseURL: 'http://localhost:3000',
});

const getAccessToken = () => {
  const accessToken = localStorage.getItem("accessToken");
  return accessToken
};

apiClient.interceptors.request.use((config) => {
  const accessToken = getAccessToken()
  if (accessToken) {
    config.headers.Authorization = `Bearer ${accessToken}`;
  }
  return config;
});

export default apiClient;

import { useContext } from "react";
import { AuthContext } from "../context/AuthContext";
import { useLogoutAuth } from "./useAuth";
import jwt_decode from "jwt-decode";
export const useStartup = () => {
  const { auth } = useContext(AuthContext);
  const { logout } = useLogoutAuth();
  let currentDate = new Date();
  console.log(auth);
  if (auth === null) {
    logout();
  } else if (auth.accessToken) {
    let decodedToken = jwt_decode(auth.accessToken);
    // JWT exp is in seconds
    if (decodedToken.exp * 1000 < currentDate.getTime()) {
      console.log("Token expired.");
      logout();
    } else {
      console.log("Valid token", decodedToken.exp);
    }
  } else {
    logout();
  }
};
